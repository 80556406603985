.signin {
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(-135deg,#c850c0,#4158d0); */
  background: url(../../images/signin.jfif);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.signin::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.signin_content {
  position: relative;
  width: 900px;
  height: 500px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 50px;
}

.signin_left {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin_left img {
  width: 130px;
}

.signin_right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signin_right h2 {
  font-size: 24px;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.signin_input_row {
  margin-bottom: 20px;
  width: 300px;
  position: relative;
}

.signin_input_row svg {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.signin_right input {
  border: 0;
  font-weight: bold;
  font-size: 15px;
  color: #666666;
  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 60px;
  transition: all 0.3s ease-out;
}

.signin_right button {
  width: 300px;
  height: 50px;
  border-radius: 25px;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  background: #e41e26;
  font-size: 16px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.error {
  color: #e41e26;
  font-weight: bold;
  margin-bottom: 20px;
}

.signin_right input:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

@media (max-width: 1100px) {
  .signin_content {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .signin_content {
    height: auto;
    justify-content: center;
    padding: 50px 20px;
  }

  .signin_left {
    display: none;
  }

  .signin_right {
    width: 100%;
  }

  .signin_input_row {
    width: 100%;
  }
  .signin_right button {
    width: 100%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  line-height: 1.4;
  color: #1c1c1c;
  font-family: "Poppins";
}

button {
  cursor: pointer;
}

input,
select,
textarea,
button {
  outline: none;
}

.noyet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #1c1c1c;
  color: #e41e26;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.calendar_wrapper {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../images/signin.jfif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.calendar_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}

.calendar_wrapper h2 {
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.calendar_content {
  position: relative;
}

.react-calendar {
  width: 90% !important;
  margin: 0 auto !important;
}

@media (max-width: 400px) {
  .calendar_wrapper h2 {
    font-size: 24px;
  }
}

.traning_list_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
}

.list_wrap {
  width: 700px;
  height: 400px;
  margin: 60px auto;
  position: relative;
  overflow-y: scroll;
  padding: 0 30px;
}

.list_wrap_item {
  width: 100%;
  height: 60px;
  overflow: hidden;
  border-radius: 10px;
  background: #141414;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: rgb(231, 231, 231);
  cursor: pointer;
}

.list_wrap_item_left {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list_wrap_item_right {
  background: #474747;
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list_wrap::-webkit-scrollbar {
  border-radius: 30px;
  background: #141414;
}

/* Track */
.list_wrap::-webkit-scrollbar-track {
  background: #141414;
  border-radius: 30px;
}

/* Handle */
.list_wrap::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 30px;
}

/* Handle on hover */
.list_wrap::-webkit-scrollbar-thumb:hover {
  background: #888;
}

@media (max-width: 800px) {
  .list_wrap {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .list_wrap {
    padding: 0 10px;
    height: 80%;
  }

  .list_wrap_item {
    font-size: 14px;
  }

  .list_wrap_item_left {
    width: 120px;
  }

  .list_wrap_item_right {
    width: 120px;
  }
}

.navbar {
  width: 100%;
  height: 80px;
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1c1c1c;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.navbar img {
  width: 50px;
}

.navbar span {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e41e26;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

.navbar span:hover {
  opacity: 0.7;
}

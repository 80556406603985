.popup_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_holder {
  width: 450px;
  height: 500px;
  border-radius: 10px;
  padding: 20px;
  background: #222;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(224, 224, 224);
}

.popup_holder h2 {
  color: rgb(224, 224, 224);
  text-transform: unset;
  margin-bottom: 20px;
}

.popup_holder p {
  width: 100%;
}

.summary {
  width: 100%;
  border-radius: 10px;
  margin: 20px 0;
  background: #fff;
  padding: 30px 10px;
  color: #1c1c1c;
}

.instructor {
  margin-top: 20px;
}

.buttons {
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons button {
  width: 130px;
  height: 40px;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

@media (max-width: 500px) {
  .popup_holder {
    width: 90%;
  }
}

.primary {
  background: rgb(15, 117, 201);
}

.warning {
  background: #e41e26;
}

.success {
  background: rgb(11, 122, 48);
}
